import React, { Component } from "react";
import Fade from "react-reveal";

class Resume extends Component {
  getRandomColor() {
    let letters = "0123456789ABCDEF";
    let color = "#";
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }

  render() {
    if (!this.props.data) return null;

    const skillmessage = this.props.data.skillmessage;
    const education = this.props.data.education.map(function (education) {
      return (
        <div key={education.school}>
          <h3>{education.school}</h3>
          <p className="info">
            {education.degree} <span></span>
            <em className="date">{education.graduated}</em>
          </p>
          <p>{education.description}</p>
        </div>
      );
    });

    const work1 = this.props.data.work1.map(function (work1) {
      return (
        <div key={work1.company}>
          <h3>{work1.company}</h3>
          <p className="info">
            {work1.title}
            <span></span> <em className="date">{work1.years}</em>
          </p>
          <p>{work1.description}</p>
        </div>
      );
    });

    const work2 = this.props.data.work2.map(function (work2) {
      return (
        <div key={work2.company}>
          <h3>{work2.company}</h3>
          <p className="info">
            {work2.title}
            <span></span> <em className="date">{work2.years}</em>
          </p>
          <p>{work2.description}</p>
        </div>
      );
    });

    const work3 = this.props.data.work3.map(function (work3) {
      return (
        <div key={work3.company}>
          <h3>{work3.company}</h3>
          <p className="info">
            {work3.title}
            <span></span> <em className="date">{work3.years}</em>
          </p>
          <p>{work3.description}</p>
        </div>
      );
    });

    const skills = this.props.data.skills.map((skills) => {
      const backgroundColor = this.getRandomColor();
      const className = "bar-expand " + skills.name.toLowerCase();
      const width = skills.level;

      return (
        <li key={skills.name}>
          <span style={{ width, backgroundColor }} className={className}></span>
          <em>{skills.name}</em>
        </li>
      );
    });

    const work4 = this.props.data.work4.map(function (work4) {
      return (
        <div key={work4.company}>
          <h3>{work4.company}</h3>
          <p className="info">
            {work4.title}
            <span></span> <em className="date">{work4.years}</em>
          </p>
          <p>{work4.description}</p>
        </div>
      );
    });

    const work5 = this.props.data.work5.map(function (work5) {
      return (
        <div key={work5.company}>
          <h3>{work5.company}</h3>
          <p className="info">
            {work5.title}
            <span></span> <em className="date">{work5.years}</em>
          </p>
          <p>{work5.description}</p>
        </div>
      );
    });

    return (
      <section id="resume" style={{marginTop: 0, paddingTop: 0}}>
        {/* <img style={{marginTop:0}}
                  src="robocza.jpg"
                  alt="Serwis AQUA - ubrania pracowników"
                /> */}
        <Fade duration={1000}>
          <div id="zakladyprodukcyjne" className="row education">
            <div className="twelve columns header-col">
              {/* <h1 style={{color: "#0344ad"}}>
                Pralnia dla zakładów produkcyjnych
              </h1> */}
            </div>
            <img style={{marginTop:0}}
                  src="robocza.jpg"
                  alt="Serwis Aqua - profesjonalna pralnia"
                />    
            {/* <div className="twelve columns main-col">
              <div className="row item"> */}
                <div className="twelve columns">{education}</div>
              </div>
            {/* </div>
          </div>  */}
        </Fade>
        
        {/* <img style={{marginTop: 20}}
                  src="reczniki2.jpg"
                  alt="Serwis AQUA - hotele"
                /> */}
        <Fade duration={1000}>
          <div id="hotele" className="row work">
            <div className="twelve columns header-col">
              {/* <h1 style={{color: "#0344ad"}}>
                Obsługa Hoteli oraz restauracji
              </h1> */}
            </div>
            <img style={{marginTop: 20}}
                  src="reczniki2.jpg"
                  alt="Serwis Aqua - profesjonalna pralnia"
                />
            <div className="twelve columns main-col">{work1}</div>
          </div>
        </Fade>

        {/* <img style={{marginTop: 20}}
                  src="truck.jpeg"
                  alt="Serwis AQUA - pralnia"
                /> */}
        <Fade duration={1000}>
          <div id="klient" className="row skill">
            <div className="twelve columns header-col">
              {/* <h1 style={{color: "#0344ad"}}>
                Obsługa Klienta indywidualnego
              </h1> */}
              </div>
              <img style={{marginTop: 20}}
                  src="truck.jpeg"
                  alt="Serwis Aqua - profesjonalna pralnia"
                />
              <div className="twelve columns main-col">{work2}
            </div>
            </div>
        </Fade>

        {/* <img style={{marginTop: 20}}
                  src="truck.jpeg"
                  alt="Serwis AQUA - pralnia"
                /> */}
        <Fade duration={1000}>
          <div id="dywany" className="row skill">
            <div className="twelve columns header-col">
              {/* <h1 style={{color: "#0344ad"}}>
                Pralnia dywanów
              </h1> */}
              </div>
              <img style={{marginTop: 20}}
                  src="carpet.jpg"
                  alt="Serwis Aqua - profesjonalna pralnia"
                />
              <div className="twelve columns main-col">{work3}
            </div>
            </div>
        </Fade>

        <Fade duration={1000}>
          <div id="skora" className="row skill">
            <div className="twelve columns header-col">
              {/* <h1 style={{color: "#0344ad"}}>
                Pralnia dywanów
              </h1> */}
              </div>
              <img style={{marginTop: 20}}
                  src="skory.jpg"
                  alt="Serwis Aqua - profesjonalna pralnia"
                />
              <div className="twelve columns main-col">{work4}
            </div>
            </div>
        </Fade>

        <Fade duration={1000}>
          <div id="buty" className="row skill">
            <div className="twelve columns header-col">
              {/* <h1 style={{color: "#0344ad"}}>
                Pralnia dywanów
              </h1> */}
              </div>
              <img style={{marginTop: 20}}
                  src="buty.jpg"
                  alt="Serwis Aqua - profesjonalna pralnia"
                />
              <div className="twelve columns main-col">{work5}
            </div>
            </div>
        </Fade>
      </section>
    );
  }
}

export default Resume;
